<template>
    <div v-if="tabs && tabs.length === 1 && !iframeSrc && !$slots.detailTab" class="row-container">
        <component v-if="tabs[0].component" :is="tabs[0].component" v-bind="tabs[0].props"></component>
        <slot v-else :name="`tab(${tabs[0].id})`"></slot>
    </div>
    <OTabs v-else-if="tabs">
        <template v-for="tab in topTabs" :key="tab.id">
            <OTab :id="tab.id" :active="tab.active" :title="tab.title" :disableKeepAlive="tab.disableKeepAlive">
                <component v-if="tab.component" :is="tab.component" v-bind="tab.props"></component>
                <CustomRegisterTab v-else-if="tab.url" :url="tab.url" :useLoadingMessage="tab.useLoadingMessage" :menuTabs="menuTabs" />
                <slot v-else :name="`tab(${tab.id})`"></slot>
            </OTab>
        </template>

        <OTab v-if="$slots.detailTab" :title="$t(detailTabTitle)" :active="detailsActive">
                <slot name="detailTab"></slot>
        </OTab>
        <OTab v-else-if="iframeSrc" :title="$t(detailTabTitle)" :active="detailsActive" alwaysRender>
            <component :is="iframeComponent" :ref="setIframeRef" :src="iframeSrc" @load="handleLoaded" :enabled="iframeEnabled" preserveQueryParams :useLoadingMessage="props.menuTabs.gridProps.useDetailLoadingMessage" />
        </OTab>

        <template v-for="tab in bottomTabs" :key="tab.id">
            <OTab :id="tab.id" :active="tab.active" :title="tab.title" :disableKeepAlive="tab.disableKeepAlive">
                <component v-if="tab.component" :is="tab.component" v-bind="tab.props"></component>
                <CustomRegisterTab v-else-if="tab.url" :url="tab.url" :useLoadingMessage="tab.useLoadingMessage" :menuTabs="menuTabs" />
                <slot v-else :name="`tab(${tab.id})`"></slot>
            </OTab>
        </template>
    </OTabs>
    <template v-else-if="$slots.detailTab">
        <slot v-if="iframeEnabled" name="detailTab"></slot>
    </template>
    <component :is="iframeComponent" v-else :ref="setIframeRef" :src="iframeSrc" @load="handleLoaded" :enabled="iframeEnabled" preserveQueryParams :useLoadingMessage="props.menuTabs.gridProps.useDetailLoadingMessage" />
</template>

<script lang="ts">
export interface DetailsTabProps {
    menuTabs: DataGridMenuControl;
    tabs?: IDataGridMenuTab[];
    iframeSrc?: string;
    detailTabTitle: string;
};
</script>

<script setup lang="ts">
import type { default as DataGridMenuControl, IDataGridMenuTab } from './DataGrid.GridMenu.ts';
import { OIframe, ODetailsIframe } from 'o365-ui-components';
import { computed, watch, toRef } from 'vue';
import { useAsyncComponent } from 'o365-vue-utils';

const props = defineProps<DetailsTabProps>();
const CustomRegisterTab = useAsyncComponent('./components.SidePanel.CustomRegisterTab.vue', {
    importFn: () => import('./components.SidePanel.CustomRegisterTab.vue')
});
let iframeComponent = OIframe

if (props.menuTabs.gridProps.detailMessage != null) {
    iframeComponent = ODetailsIframe;
    const detailMessage = toRef(() => props.menuTabs.gridProps.detailMessage)
    watch(detailMessage, (newMessage, oldMessage) => {
        if (iframeRef && iframeRef.iFrameRef && iframeRef.post) {
            if (iframeRef.iFrameRef.isLoading) {
                iframeRef.validateAndSetSrc(props.iframeSrc, true)
            } else {
                iframeRef.post(newMessage)
            }
        }
    });
}

let iframeRef = null;
const iframeEnabled = computed(() => {
    return props.menuTabs.sizerState !== (props.menuTabs.leftSidepanel ? 'collapsedLeft' : 'collapsedRight')  && props.menuTabs.activeTab === 'details';
});

function setIframeRef(value) {
    iframeRef = value;
}

function getIframeRef(pRef) {
    if (pRef) {
        if (pRef.iframeRef) {
            return getIframeRef(pRef.iframeRef);
        } else if (pRef.iFrameRef) {
            return getIframeRef(pRef.iFrameRef);
        }
    }
    return pRef;
}

function handleLoaded(e: Event) {
    if (props.iframeSrc) {
        props.menuTabs.handleIframeLoaded(props.iframeSrc, e, getIframeRef(iframeRef));
    }
}

//--- Details Sub-tabs ---
const topTabs = computed(() => {
    return props.tabs?.filter(tab => tab.top);
});
const bottomTabs = computed(() => {
    return props.tabs?.filter(tab => !tab.top);
});

const detailsActive = props.tabs?.every(tab => !tab.active);

</script>